import { ethers } from "ethers";
import { Interface, FormatTypes } from "@ethersproject/abi";
import { default as erc721abi } from "./erc721-abi.json";

const KHUGA_CONTRACT_ADDRESS = process.env.REACT_APP_KHUGA_CONTRACT_ADDRESS;
const khugaABI = new Interface(erc721abi).format(FormatTypes.full);
const RPC_PROVIDER = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_KHUGA_JSON_RPC_NODE);
const KhugaContractReader = new ethers.Contract(KHUGA_CONTRACT_ADDRESS, khugaABI, RPC_PROVIDER);

const KhugaStageToPriceBindings = [
  process.env.REACT_APP_KHUGA_MIAWWLIST_MINT_COST, // Miawlist
  process.env.REACT_APP_KHUGA_ALLOWLIST_MINT_COST, // Allowlist
  process.env.REACT_APP_KHUGA_PUBLIC_MINT_COST, // Public
];

export const KhugaStageMaxAmountPerTx = [1, 1, 2];
export const KhugaStageMaxAmountPerAddress = [1, 1, 2];

// Example:
// whitelistType: 0..2 ["Guaranteed Mint", "Allowlist Mint", "Public Mint"]
// Merkle Proof: [0x0ad2638c89ef98de7e450ea32c9d37a3db5d4d3634d8d6464dcb20564ad0ccd6]
export const whitelistMint = async (whitelistType, mintAmount, merkleProof) => {
  if (!window.ethereum) throw "No Injected Web3 Provider Found";
  const ethereum = window.ethereum;
  const accounts = await ethereum.request({
    method: "eth_requestAccounts",
  });

  const provider = new ethers.providers.Web3Provider(ethereum);
  const walletAddress = accounts[0];
  const signer = provider.getSigner(walletAddress);
  console.log(walletAddress);

  const khugaContract = new ethers.Contract(KHUGA_CONTRACT_ADDRESS, khugaABI, signer);
  const whitelistMint = await khugaContract.populateTransaction.whitelistMint(whitelistType, mintAmount, merkleProof);
  whitelistMint.value = new ethers.utils.parseEther(KhugaStageToPriceBindings[whitelistType]).mul(ethers.BigNumber.from(mintAmount));
  whitelistMint.chainId = process.env.REACT_APP_KHUGA_NFT_CHAIN_ID;
  whitelistMint.gasLimit = 155000;

  const whitelistMintTx = await signer.sendTransaction(whitelistMint).catch((e) => {
    throw e.error.data.data.message;
  });
  const mintReceipt = await whitelistMintTx.wait();
  if (mintReceipt.status === 0) throw new Error("Approve transaction failed");

  return {
    txHash: whitelistMintTx.hash,
    receiptStatus: mintReceipt.status,
  };
};

export const publicMint = async (mintAmount) => {
  if (!window.ethereum) throw "No Injected Web3 Provider Found";
  const ethereum = window.ethereum;
  const accounts = await ethereum.request({
    method: "eth_requestAccounts",
  });

  const provider = new ethers.providers.Web3Provider(ethereum);
  const walletAddress = accounts[0];
  const signer = provider.getSigner(walletAddress);
  console.log(walletAddress);

  const khugaContract = new ethers.Contract(KHUGA_CONTRACT_ADDRESS, khugaABI, signer);
  const publicMint = await khugaContract.populateTransaction.mint(mintAmount);
  publicMint.value = new ethers.utils.parseEther(KhugaStageToPriceBindings[2]).mul(ethers.BigNumber.from(mintAmount));
  publicMint.chainId = process.env.REACT_APP_KHUGA_NFT_CHAIN_ID;
  publicMint.gasLimit = 155000;

  const publicMintTx = await signer.sendTransaction(publicMint).catch((e) => {
    throw e.error.data.data.message;
  });
  const mintReceipt = await publicMintTx.wait();
  if (mintReceipt.status === 0) throw new Error("Approve transaction failed");

  return {
    txHash: publicMintTx.hash,
    receiptStatus: mintReceipt.status,
  };
};

export const getMintStage = async () => {
  // Stages:
  // 0 => Miawlist Mint
  // 1 => Allowlist Mint
  // 2 => Public Mint
  // 255 => Not Started
  return await KhugaContractReader.getMintStage();
};

export const getMintPrice = async (stage) => {
  const priceWei = await KhugaContractReader.getMintCost(stage);
  return ethers.utils.formatEther(priceWei);
};

// mintedAmount / maxSupply => i.e: 2000 / 5555

export const getMintedAmount = async () => {
  return (await KhugaContractReader.totalSupply()).toNumber();
};

export const getMaxSupply = async () => {
  return (await KhugaContractReader.maxSupply()).toNumber();
};

export const isPaused = async () => {
  return await KhugaContractReader.paused();
};

export const getStageInfo = async (stage) => {
  return await KhugaContractReader.mintStages(stage);
};

export const getClaimedAmount = async (stage, address) => {
  return (await KhugaContractReader.getClaimedAmount(stage, address)).toNumber();
};
