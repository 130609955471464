import { Link } from "react-router-dom";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import CustomCursor from "custom-cursor-react";
import "custom-cursor-react/dist/index.css";
import "@splidejs/react-splide/css";
import { useRef } from "react";

export default function HomePage() {
  const homeRef = useRef();
  const aboutRef = useRef();
  const galleryRef = useRef();
  const connectRef = useRef();

  return (
    <main className="cursor-none">
      <CustomCursor
        targets={[".link-to"]}
        customClass="custom-cursor"
        dimensions={60}
        fill="transparent"
        smoothness={{
          movement: 0.2,
          scale: 0.1,
          opacity: 0.2,
        }}
        opacity={1}
        targetOpacity={1}
        targetScale={1}
      />

      <div className="bg-white bg-center bg-cover" style={{ backgroundImage: `url('/img/bg/overlay.webp')` }} ref={homeRef}>
        <section className="relative w-full aspect-[16/11.2]">
          <div className="absolute inset-0 w-full bg-top bg-cover bg-no-repeat deco-hero"></div>
          <div className="w-full h-screen flex items-center justify-center pt-4 sm:pt-6 md:pt-8 lg:pt-24">
            <div className="relative mt-14 md:mt-0">
              {/* <img src="/img/logo/logo-tagline.webp" alt="Logo" className="h-24 sm:h-32 md:h-48 lg:h-56 mx-auto" /> */}
              <img src="/img/logo/logo-tagline.webp" alt="Logo" className="w-[90%] lg:w-[100%] mx-auto" />
              <div className="relative md:absolute -top-[50%] flex justify-center w-full">
                <div className="flex flex-col md:flex-row items-center gap-6 sm:gap-7 md:gap-10 lg:gap-14 mt-8 md:mt-0">
                  <button
                    className="hover:scale-105 transition-all link-to cursor-none"
                    onClick={() => homeRef.current?.scrollIntoView({ behavior: "smooth" })}
                  >
                    <img
                      src="/img/menu/home.webp"
                      onMouseOver={(e) => (e.currentTarget.src = "/img/menu/home-active.webp")}
                      onMouseOut={(e) => (e.currentTarget.src = "/img/menu/home.webp")}
                      alt="Home"
                      className="h-7 md:h-9 2xl:h-12"
                    />
                  </button>
                  <button
                    className="hover:scale-105 transition-all link-to cursor-none"
                    onClick={() => aboutRef.current?.scrollIntoView({ behavior: "smooth" })}
                  >
                    <img
                      src="/img/menu/about.webp"
                      onMouseOver={(e) => (e.currentTarget.src = "/img/menu/about-active.webp")}
                      onMouseOut={(e) => (e.currentTarget.src = "/img/menu/about.webp")}
                      alt="About"
                      className="h-7 md:h-9 2xl:h-12"
                    />
                  </button>
                  <button
                    className="hover:scale-105 transition-all link-to cursor-none"
                    onClick={() => galleryRef.current?.scrollIntoView({ behavior: "smooth" })}
                  >
                    <img
                      src="/img/menu/gallery.webp"
                      onMouseOver={(e) => (e.currentTarget.src = "/img/menu/gallery-active.webp")}
                      onMouseOut={(e) => (e.currentTarget.src = "/img/menu/gallery.webp")}
                      alt="Gallery"
                      className="h-7 md:h-9 2xl:h-12"
                    />
                  </button>
                  <button
                    className="hover:scale-105 transition-all link-to cursor-none"
                    onClick={() => connectRef.current?.scrollIntoView({ behavior: "smooth" })}
                  >
                    <img
                      src="/img/menu/connect.webp"
                      onMouseOver={(e) => (e.currentTarget.src = "/img/menu/connect-active.webp")}
                      onMouseOut={(e) => (e.currentTarget.src = "/img/menu/connect.webp")}
                      alt="Connect"
                      className="h-7 md:h-9 2xl:h-12"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative min-h-screen w-full aspect-[9/16] md:aspect-[16/11] -mt-0 md:-mt-44" ref={aboutRef}>
          <div className="absolute inset-0 w-full bg-top bg-cover bg-no-repeat deco-about"></div>
          <div className="flex items-center justify-center h-full">
            <div className="w-full max-w-3xl px-4 mx-auto text-center">
              <p className="font-pencil text-base sm:text-lg md:text-xl lg:text-2xl pt-20 sm:pt-12 md:pt-0">
                “Here at Koompalokaa (read: Kumpaloka), every human being is different and unique, sometimes a little bit weird or even absurd. <br />
                <br />
                They always embrace themselves with colors while tending to remain calm, mindful, intuitive, meditative, caring, and into nature. <br />
                <br />
                Their life isn't too mainstream or too left behind. The people of Koompalokaa know that it is imperative to maintain their life balance and
                enjoy a meaningful life chapter for the rest of their lives.”
              </p>
            </div>
          </div>
        </section>

        <img src="/img/bg/deco-top-gallery.webp" alt="Deco" className="block bg-contain w-full md:hidden mt-4" />
      </div>

      <div className="py-16 md:py-32 bg-center bg-cover -mt-2 relative" style={{ backgroundImage: `url('/img/bg/slide.webp')` }} ref={galleryRef}>
        <div className="container mx-auto px-4">
          <div className="md:hidden flex flex-col gap-6 mb-12">
            <div>
              <img src="/img/nft/kala-mono-active.webp" alt="Kala Mono" className="h-10 mx-auto" />
            </div>
            <div>
              <img src="/img/nft/kala-spektrum-active.webp" alt="Kala Mono" className="h-10 mx-auto" />
            </div>
            <div>
              <img src="/img/nft/kala-bumi-active.webp" alt="Kala Mono" className="h-10 mx-auto" />
            </div>
          </div>
          <div className="hidden md:grid grid-cols-3 gap-6 mb-16 px-0 md:px-32">
            <div>
              <img
                src="/img/nft/kala-mono.webp"
                onMouseOver={(e) => (e.currentTarget.src = "/img/nft/kala-mono-active.webp")}
                onMouseOut={(e) => (e.currentTarget.src = "/img/nft/kala-mono.webp")}
                alt="Kala Mono"
                className="h-12 md:h-20"
              />
            </div>
            <div>
              <img
                src="/img/nft/kala-spektrum.webp"
                onMouseOver={(e) => (e.currentTarget.src = "/img/nft/kala-spektrum-active.webp")}
                onMouseOut={(e) => (e.currentTarget.src = "/img/nft/kala-spektrum.webp")}
                alt="Kala Spektrumn"
                className="h-12 md:h-20 mx-auto"
              />
            </div>
            <div>
              <img
                src="/img/nft/kala-bumi.webp"
                onMouseOver={(e) => (e.currentTarget.src = "/img/nft/kala-bumi-active.webp")}
                onMouseOut={(e) => (e.currentTarget.src = "/img/nft/kala-bumi.webp")}
                alt="Kala Bumi"
                className="h-12 md:h-20 ml-auto"
              />
            </div>
          </div>
          <Splide
            aria-label="Collections"
            hasTrack={false}
            options={{
              type: "loop",
              rewind: true,
              perPage: 3,
              perMove: 1,
              gap: "2rem",
              padding: "8rem",
              breakpoints: {
                640: {
                  perPage: 1,
                  gap: "1rem",
                  padding: "5rem",
                },
              },
            }}
          >
            <SplideTrack>
              <SplideSlide>
                <img src="/img/nft/01.webp" alt="01" />
              </SplideSlide>
              <SplideSlide>
                <img src="/img/nft/02.webp" alt="02" />
              </SplideSlide>
              <SplideSlide>
                <img src="/img/nft/03.webp" alt="03" />
              </SplideSlide>
              <SplideSlide>
                <img src="/img/nft/04.webp" alt="04" />
              </SplideSlide>
              <SplideSlide>
                <img src="/img/nft/05.webp" alt="05" />
              </SplideSlide>
            </SplideTrack>
            <div className="pointer-events-none absolute top-0 bottom-0 w-36 left-0 bg-gradient-to-r from-black via-black/50"></div>
            <div className="pointer-events-none absolute top-0 bottom-0 w-36 right-0 bg-gradient-to-l from-black via-black/50"></div>
            <div className="splide__arrows">
              <button className="splide__arrow splide__arrow--prev" style={{ background: `none` }}>
                <img src="/img/arrow-l.webp" alt="Arrow Left" className="w-24" />
              </button>
              <button className="splide__arrow splide__arrow--next" style={{ background: `none` }}>
                <img src="/img/arrow-r.webp" alt="Arrow Right" className="w-24" />
              </button>
            </div>
          </Splide>
        </div>
      </div>
      <div className="bg-white bg-center bg-cover" style={{ backgroundImage: `url('/img/bg/overlay.webp')` }}>
        <img src="/img/bg/deco-bw.webp" alt="Deco" className="-mt-2" />
        <section className="relative w-full md:aspect-[16/8.6]" ref={connectRef}>
          <div className="absolute inset-0 w-full bg-center bg-cover bg-no-repeat deco-social"></div>
          <div className="relative flex items-center justify-center h-full">
            <div className="w-full max-w-xl text-center mt-20 md:mt-0 pb-28">
              <img src="/img/social/get-connect.webp" alt="Get Connect" className="h-6 sm:h-8 md:h-10 lg:h-12 mx-auto mb-20 mt-12 sm:mt-6 md:mt-4 lg:mt-0" />
              <div className="space-y-8">
                <div>
                  <a href="/" className="inline-block hover:scale-105 transition-all cursor-none">
                    <img src="/img/social/os.webp" alt="OpenSea" className="h-10 sm:h-12 md:h-14 lg:h-16 mx-auto" />
                  </a>
                </div>
                <div>
                  <a href="/" className="inline-block hover:scale-105 transition-all cursor-none">
                    <img src="/img/social/tw.webp" alt="Twitter" className="h-10 sm:h-12 md:h-14 lg:h-16 mx-auto" />
                  </a>
                </div>
                <div>
                  <a href="/" className="inline-block hover:scale-105 transition-all cursor-none">
                    <img src="/img/social/ig.webp" alt="Instagram" className="h-10 sm:h-12 md:h-14 lg:h-16 mx-auto" />
                  </a>
                </div>
              </div>
              <img src="/img/social/by.webp" alt="By" className="absolute bottom-0 inset-x-0 mx-auto h-8 md:h-12 mb-4" />
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}
